import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import '../App.css';


import {Link as RouterLink} from "react-router-dom";

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    // window.alert(5 + 6);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);

    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);

    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        // window.alert("hello");
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);

    };

    return (
        <AppBar position="sticky"
        sx={{backgroundColor:"#F16323"}}>
        {/*//     color={"#F16323"}>*/}
        {/*    >*/}
            <Container maxWidth="xl">
                <Toolbar disableGutters>


                    <Box sx={{flexGrow: 1, display: {md: 'flex'}}}>


                        <Button
                            variant={"text"} color="primary"
                            component={RouterLink} to={"/"}
                            // key={index}
                            // startIcon={value.startIcon}
                            sx={{maxWidth: "150px" , color: 'white', display: 'block'}}
                        >
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{mr: 2, display: {md: 'flex'}}}
                            >
                                Tazcarp
                            </Typography>
                        </Button>


                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        display: {justifyContent: "flex-end", alignItems: "flex-end", xs: 'none', md: 'flex'}
                    }}>

                        <Button
                            variant={"text"} color="primary"
                            component={RouterLink} to={"/about"}
                            // key={index}
                            // startIcon={value.startIcon}
                            sx={{my: 2, color: 'white', display: 'block'}}
                        >
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{mr: 2, display: {md: 'flex'}}}
                            >
                                About
                            </Typography>
                        </Button>

                        {/*<Button*/}
                        {/*    variant={"text"} color="primary"*/}
                        {/*    component={RouterLink} to={"/contact"}*/}
                        {/*    // key={index}*/}
                        {/*    // startIcon={value.startIcon}*/}
                        {/*    sx={{my: 2, color: 'white', display: 'block'}}*/}
                        {/*>*/}
                        {/*    <Typography*/}
                        {/*        variant="h6"*/}
                        {/*        noWrap*/}
                        {/*        component="div"*/}
                        {/*        sx={{mr: 2, display: {md: 'flex'}}}*/}
                        {/*    >*/}
                        {/*        Contact*/}
                        {/*    </Typography>*/}
                        {/*</Button>*/}

                        <Button
                            variant={"text"} color="primary"
                            component={RouterLink} to={"/privacy"}

                            sx={{my: 2, color: 'white', display: 'block'}}


                        >
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{mr: 2, display: {md: 'flex'}}}
                            >
                                Privacy Policy
                            </Typography>
                        </Button>

                    </Box>

                    <Box sx={{display: {xs: 'block', md: 'none'}}}>
                        <Tooltip title="Open Navigation">
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{mr: 2}}
                                onClick={handleOpenUserMenu} sx={{p: 0}}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >

                            <MenuItem
                                component={RouterLink} to={"/about"}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{mr: 2, display: {md: 'flex'}}}
                                >
                                    About
                                </Typography>

                            </MenuItem>

                            {/*<MenuItem*/}
                            {/*    component={RouterLink} to={"/contact"}*/}
                            {/*>*/}
                            {/*    <Typography*/}
                            {/*        variant="h6"*/}
                            {/*        noWrap*/}
                            {/*        component="div"*/}
                            {/*        sx={{mr: 2, display: {md: 'flex'}}}*/}
                            {/*    >*/}
                            {/*        Contact*/}
                            {/*    </Typography>*/}

                            {/*</MenuItem>*/}

                            <MenuItem

                                component={RouterLink} to={"/privacy"}>

                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{mr: 2, display: {md: 'flex'}}}
                                >
                                    Privacy Policy
                                </Typography>


                            </MenuItem>

                        </Menu>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
