import ImageTextSplit from "../components/ImageTextSplit";
import {Box} from "@mui/material";
import {useEffect} from "react";

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Box sx={{display: {xs: 'none', md: 'block'}}}>
                <img src={"Screenshot3.png"} alt={"Game Preview"} width={"100%"}/>


            </Box>


            <Box sx={{display: {xs: 'block', md: 'none'}}}>
                <img src={"ScreenShotNarrow.png"} alt={"Game Preview"} width={"100%"}/>
            </Box>

            <ImageTextSplit/>

        </div>
    );
}

