import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({
    loaderUrl: "OpenGl/Build/OpenGl.loader.js",
    dataUrl: "OpenGl/Build/OpenGl.data",
    frameworkUrl: "OpenGl/Build/OpenGl.framework.js",
    codeUrl: "OpenGl/Build/OpenGl.wasm",
});

function OpenGl() {
    return (
        <Unity
            unityContext={unityContext}
            style={{
                height: "75%",
                width: "75%",
                border: "2px solid black",
                background: "grey",
            }}
        />
    );
}

export default OpenGl;
