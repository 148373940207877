import {Paper, Stack, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {css} from "@emotion/react";
import Typography from "@mui/material/Typography";

const Footer = () => {
    const theme = useTheme()

    return (
        <Paper
            elevation={0}
            sx={{background: "#F16323"}}
        >
            <Stack
                direction={{xs: 'column', sm: 'row'}}
                spacing={{xs: 1, sm: 2, md: 4}}

                justifyContent="space-around"
                alignItems="center"
            >
                {/*<div>Item 1</div>*/}
                {/*<div></div>*/}
                <Stack
                    direction={{xs: 'row', md: 'row'}}
                    spacing={{xs: 1, sm: 2, md: 4}}
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Box>
                        <a href='https://play.google.com/store/apps/developer?id=Tazcarp'>
                            <img
                                id="getOnGoogle"
                                alt='Get it on Google Play'
                                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                            />
                        </a>
                    </Box>

                    <Typography>

                        Google Play and the Google Play logo are trademarks of Google LLC.

                    </Typography>


                </Stack>
            </Stack>
        </Paper>


    )
}

export default Footer;
