import ImageTextSplit from "../components/ImageTextSplit";
import {Paper, Stack, useTheme} from "@mui/material";
import {css} from "@emotion/react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";

export default function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const theme = useTheme()
    return (
        <Stack
            spacing={{xs: 1, sm: 2, md: 4}}
            //
            direction="column"
            justifyContent="center"
            alignItems="center"
            paddingTop="1em"
            paddingBottom="1em"
        >
            <Paper
                // sc={{padding: "1em"}}>
                sx={{width: "75%"}}
                elevation={2}>
                <Typography
                    sx={{
                        textAlign: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h5">

                    Tazcarp

                </Typography>

                <Typography
                    sx={{
                        textAlign: "justify",
                        textAlignLast: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h6">

                    Tazcarp is a the name I came up with to publish games beacause in order to publish a
                    game you need to pick a "publisher name" so I just made up a word that sounded normal enough. Ok but
                    why make time to make a website as well? I thought it would be fun making and hosting a website.
                </Typography>

            </Paper>

            <Paper
                // sc={{padding: "1em"}}>
                sx={{width: "75%"}}
                elevation={2}>
                <Typography
                    sx={{
                        textAlign: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h5">

                    What was used to make this website?

                </Typography>

                <Typography
                    sx={{
                        textAlign: "justify",
                        textAlignLast: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h6">

                    This website was made with react. Material-ui was used for styling and Icons and its hosted on
                    firebase.
                </Typography>

            </Paper>

            <Paper
                // sc={{padding: "1em"}}>
                sx={{width: "75%"}}
                elevation={2}>
                <Typography
                    sx={{
                        textAlign: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h5">

                    Who am I?
                </Typography>

                <Typography
                    sx={{
                        textAlign: "justify",
                        textAlignLast: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h6">
                    I'm Kornel :)
                </Typography>

            </Paper>

            <Paper
                // sc={{padding: "1em"}}>
                sx={{width: "75%"}}
                elevation={2}>
                <Typography
                    sx={{
                        textAlign: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h5">

                    Are you going to make any more games or is this a once off? </Typography>

                <Typography
                    sx={{
                        textAlign: "justify",
                        textAlignLast: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h6">
                    I still have a few Ideas as to what I could add to Jungel Runners and once thats done I have a few
                    ideas for some other fun projects.
                </Typography>

            </Paper>

            <Paper
                // sc={{padding: "1em"}}>
                sx={{width: "75%"}}
                elevation={2}>
                <Typography
                    sx={{
                        textAlign: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h5">

                    How do we contact you?
                </Typography>

                <Typography
                    sx={{
                        textAlign: "justify",
                        textAlignLast: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h6">

                    You can look for my developer details on my google play account which is in the footer or you can
                    reach me at tazcarpoffical@gmail.com
                </Typography>

            </Paper>
        </Stack>

    );
}

