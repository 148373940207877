import {Paper, Stack} from "@mui/material";
import OpenGl from "../components/OpenGl";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center">
            <OpenGl/>
            <Paper
                // sc={{padding: "1em"}}>
                sx={{width: "75%"}}
                elevation={2}>
                <Typography
                    sx={{
                        textAlign: "center",
                        display: {padding: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h5">

                    Please note as this is instance is running on openGL and not android. As such some features are not
                    available notably Google Play integrations and Google AdMob. So enjoy the add free experience (^:

                </Typography>
            </Paper>
            <Paper
                // sc={{padding: "1em"}}>
                sx={{width: "75%"}}
                elevation={2}>
                <Typography
                    sx={{
                        textAlign: "center",
                        display: {paddingTop: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h5">

                    Controls

                </Typography>

                <Typography
                    sx={{
                        textAlign: "justify",
                        textAlignLast: "center",
                        display: {justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h6">

                    Swipe Up or Up arrow to Jump
                </Typography>
                <Typography
                    sx={{
                        textAlign: "justify",
                        textAlignLast: "center",
                        display: {paddingBottom: "1em", justifyContent: "center", alignItems: "center", md: "flex"}
                    }}
                    variant="h6">

                    Swipe Down or down arrow to Slide
                </Typography>

            </Paper>

        </Stack>
    );
}

