import logo from './logo.svg';
import './App.css';
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import ImageTextSplit from "./components/ImageTextSplit";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import About from "./pages/About";
import Contact from "./pages/Contact";

import PlayNow from "./pages/PlayNow";

const changeTitel = () => {
    document.title = "new title"
};

function App() {
    return (

        <Router>

            <AppBar/>
            <Switch>
                <Route path="/play-now">
                    <PlayNow/>
                </Route>
                <Route path="/about">
                    <About/>
                </Route>
                <Route path="/privacy">
                    <Privacy/>
                </Route>
                <Route path="/">
                    <Home/>
                </Route>
            </Switch>
            <Footer/>
        </Router>

    )
        ;
}

export default App;
