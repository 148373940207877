/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Paper, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";

export default function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const theme = useTheme()

    return (
        <Paper
            elevation={0}
        >


            {/*<Typography>*/}

            {/*    /!*We don't gather any data nor do we store any data.*!/*/}
            {/*</Typography>*/}


            <Typography>

                We don't gather any data nor do we store any data.
                <br/> But regardless here is a generic auto generated privacy policy
                <br/>
                <br/>Privacy Policy
                <br/>Last updated: February 18, 2022
                <br/>
                <br/>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of
                Your information when You use the Service and tells You about Your privacy rights and how the law
                protects You.
                <br/>
                <br/>Interpretation and Definitions
                <br/> Interpretation
                <br/> The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
                <br/>
                <br/> Definitions
                <br/>For the purposes of this Privacy Policy:
                <br/>
                <br/> Account means a unique account created for You to access our Service or parts of our Service.
                <br/>
                <br/>Affiliate means an entity that controls, is controlled by or is under common control with a party,
                where "control" means ownership of 50% or more of the shares, equity interest or other securities
                entitled to vote for election of directors or other managing authority.
                <br/>
                <br/> Application means the software program provided by the Company downloaded by You on any electronic
                device, named Jungle Runners
                <br/>
                <br/> Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                Jungle Runners.
                <br/>
                <br/> Device means any device that can access the Service such as a computer, a cellphone or a digital
                tablet.
                <br/>
                <br/> Personal Data is any information that relates to an identified or identifiable individual.
                <br/>
                <br/> Service refers to the Application.
                <br/>
                <br/> Service Provider means any natural or legal person who processes the data on behalf of the
                Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                Service, to provide the Service on behalf of the Company, to perform services related to the Service or
                to assist the Company in analyzing how the Service is used.
                <br/>
                <br/> You means the individual accessing or using the Service, or the company, or other legal entity on
                behalf of which such individual is accessing or using the Service, as applicable.
                <br/>
                <br/>The Company will take all steps reasonably necessary to ensure that Your data is treated securely
                and in
                accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of Your
                data and other personal information.
                <br/>

                <br/>Law enforcement
                <br/>Under certain circumstances, the Company may be required to disclose Your Personal Data if required
                to
                do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).
                <br/>
                <br/>Other legal requirements
                <br/>The Company may disclose Your Personal Data in the good faith belief that such action is necessary
                to:
                <br/>
                <br/>Comply with a legal obligation
                <br/>Protect and defend the rights or property of the Company
                <br/>Prevent or investigate possible wrongdoing in connection with the Service
                <br/>Protect the personal safety of Users of the Service or the public
                <br/>Protect against legal liability
                <br/>Security of Your Personal Data
                <br/>The security of Your Personal Data is important to Us, but remember that no method of transmission
                over
                the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
                acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
                <br/>
                <br/>Links to Other Websites
                <br/>Our Service may contain links to other websites that are not operated by Us. If You click on a
                third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                <br/>Privacy Policy of every site You visit.
                <br/>
                <br/>We have no control over and assume no responsibility for the content, privacy policies or practices
                of
                any third party sites or services.
                <br/>
                <br/>Changes to this Privacy Policy
                <br/>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting
                the new
                <br/>Privacy Policy on this page.
                <br/>
                <br/>Contact Us
                <br/>If you have any questions about this Privacy Policy, You can contact us:
                <br/>
                <br/>By email: tazcarpOffical@gmail.com
            </Typography>


        </Paper>
    )
}
