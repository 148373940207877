/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Paper, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Link as RouterLink} from "react-router-dom";
import * as React from "react";

export default function ImageTextSplit() {
    const theme = useTheme()

    return (
        <Paper
            elevation={0}
        >
            <Stack
                direction={{xs: 'column', md: 'row'}}
                spacing={{xs: 1, sm: 2, md: 4}}
                sx={{width: "100%"}}
                justifyContent="center"
                alignItems="center"
            >

                <Box
                    sx={{display: {xs: 'block', md: 'none'}}}
                    css={css`
                      width: 90%;
                      padding: 1em;

                      ${theme.breakpoints.up("md")} {
                        width: 50%
                      }
                    `}
                >
                    <Stack
                        direction={{xs: 'column', md: 'column'}}
                        justifyContent="center"
                        alignItems="center"
                    >

                        <Typography
                            sx={{
                                textAlign: "center",
                                display: {justifyContent: "center", alignItems: "center", md: "flex"}
                            }}
                            variant="h5">

                            RUN, JUMP, COLLECT COINS AND HAVE FUN!

                        </Typography>

                        <Typography
                            sx={{
                                textAlign: "justify",
                                textAlignLast: "center",
                                display: {justifyContent: "center", alignItems: "center", md: "flex"}
                            }}
                            variant="h6">

                            Dash through the jungle avoiding obstacle and gather coins. Grow you rooster of playable
                            characters. Best yourself with each run and most importantly of all... Don't forget to have
                            fun

                        </Typography>
                        <Stack
                            direction={{xs: 'row', md: 'row'}}
                            justifyContent="center"
                            alignItems="center">
                            <Box>
                                <a href='https://play.google.com/store/apps/details?id=com.Tazcarp.JungleRunners'>
                                    <img
                                        id="getOnGoogle"
                                        alt='Get it on Google Play'
                                        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                    />
                                </a>
                            </Box>
                            <Box>
                                <Button
                                    variant={"text"} color="secondary"
                                    component={RouterLink} to={"/play-now"}
                                    // key={index}
                                    // startIcon={value.startIcon}
                                    sx={{
                                        maxWidth: "150px",
                                        color: 'white',
                                        display: 'block',
                                        backgroundColor: "black",
                                        padding: "12px"
                                    }}
                                    align="center"

                                >
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="div"
                                        sx={{mr: 2, width:"100%"}}
                                        align="center"
                                    >
                                        Web Play
                                    </Typography>
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>

                </Box>

                <Box

                    css={css`
                      width: 90%;
                      padding: 1em;

                      ${theme.breakpoints.up("md")} {
                        width: 50%
                      }
                    `}
                >
                    <img src={"Screenshot_20220218_140202.png"} width={"100%"}/>
                </Box>

                <Box
                    css={css`
                      width: 90%;
                      padding: 1em;

                      ${theme.breakpoints.up("md")} {
                        width: 50%
                      }
                    `}
                >
                    <Stack
                        direction={{xs: 'column', md: 'column'}}
                        justifyContent="center"
                        alignItems="center"
                        sx={{display: {xs: 'none', md: 'block'}}}
                    >

                        <Typography
                            sx={{
                                textAlign: "center",
                                display: {justifyContent: "center", alignItems: "center", md: "flex"}
                            }}
                            variant="h4">

                            RUN, JUMP, COLLECT COINS AND HAVE FUN!

                        </Typography>

                        <Typography
                            sx={{
                                textAlign: "justify",
                                textAlignLast: "center",
                                display: {justifyContent: "center", alignItems: "center", md: "flex"}
                            }}
                            variant="h5">

                            Dash through the jungle avoiding obstacle and gather coins. Grow you rooster of playable
                            characters. Best yourself with each run and most importantly of all... Don't forget to have
                            fun

                        </Typography>
                        <Stack
                            direction={{xs: 'row', md: 'row'}}
                            justifyContent="center"
                            alignItems="center">
                            <Box>
                                <a href='https://play.google.com/store/apps/details?id=com.Tazcarp.JungleRunners'>
                                    <img
                                        id="getOnGoogle"
                                        alt='Get it on Google Play'
                                        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                    />
                                </a>
                            </Box>
                            <Box>
                                <Button
                                    variant={"text"} color="secondary"
                                    component={RouterLink} to={"/play-now"}
                                    // key={index}
                                    // startIcon={value.startIcon}
                                    sx={{
                                        maxWidth: "150px",
                                        color: 'white',
                                        display: 'block',
                                        backgroundColor: "black",
                                        padding: "12px"
                                    }}
                                    align="center"

                                >
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="div"
                                        sx={{mr: 2, width:"100%"}}
                                        align="center"
                                    >
                                        Web Play
                                    </Typography>
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>

                </Box>


            </Stack>
        </Paper>
    )
}
